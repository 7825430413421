<template>
  <div class="top-bar z-50">
    <!-- BEGIN: Breadcrumb -->
    <div class="-intro-x breadcrumb mr-auto hidden sm:flex">
      <a
        class=""
        href=""
      >Application</a>
      <i class="ri-arrow-right-s-line" />
      <a
        class="breadcrumb--active"
        href=""
      >Dashboard</a>
    </div>
    <!-- END: Breadcrumb -->
    <!-- BEGIN: Search -->
    <Search />
    <!-- END: Search -->
    <!-- BEGIN: Notifications -->
    <notifications />
    <!-- END: Notifications -->
    <!-- BEGIN: Account Menu -->
    <user />
    <!-- END: Account Menu -->
  </div>
</template>

<script>
import Search from "./TopBar/Search";
import Notifications from "./TopBar/Notifications";
import User from "./TopBar/User";
export default {
  name: "TopBar",
  components: { User, Notifications, Search }
};
</script>
