<template>
  <nav class="side-nav">
    <a
      href=""
      class="intro-x flex items-center pl-5 pt-4"
    >
      <img
        alt="Quant Lines"
        class="w-6"
        src="/images/logo.svg"
      >
      <span class="hidden xl:block text-white text-lg ml-3">
        Quant<span class="font-medium">Lines</span>
      </span>
    </a>
    <div class="side-nav__devider my-6" />
    <ul>
      <li>
        <a
          href="#"
          class="side-menu"
          :class="{ 'side-menu--active': $route.path === '/dashboard' }"
          @click.prevent="goToHome"
        >
          <div class="side-menu__icon flex">
            <i class="ri-lg ri-home-4-line" />
          </div>
          <div class="side-menu__title">Dashboard</div>
        </a>
      </li>
      <template
        v-for="(item, key) in menuItems"
        :key="key"
      >
        <li>
          <router-link
            :to="{ name: item.route }"
            class="side-menu"
          >
            <div class="side-menu__icon flex">
              <i :class="item.icon" />
            </div>
            <div class="side-menu__title">
              {{ item.name }}
            </div>
          </router-link>
        </li>
        <ul
          v-if="item.children.length"
          class=""
        >
          <li>
            <a
              href="side-menu-light-crud-data-list.html"
              class="side-menu"
            >
              <div class="side-menu__icon"><i data-feather="activity" /></div>
              <div class="side-menu__title">Data List</div>
            </a>
          </li>
          <li>
            <a
              href="side-menu-light-crud-form.html"
              class="side-menu"
            >
              <div class="side-menu__icon"><i data-feather="activity" /></div>
              <div class="side-menu__title">Form</div>
            </a>
          </li>
        </ul>
        <li
          v-if="item.hasDevider"
          class="side-nav__devider my-6"
        />
      </template>
    </ul>
  </nav>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Navbar",
  data() {
    return {
      menuItems: [
        {
          name: "Projects",
          route: "dashboard.client.project.index",
          icon: "ri-lg ri-folder-line",
          hasDevider: false,
          children: []
        }
      ]
    };
  },
  computed: {
    ...mapGetters("profile", ["currentUser"])
  },
  methods: {
    goToHome() {
      this.$router.push({ name: "DashboardIndex" });
    }
  }
};
</script>