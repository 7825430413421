<template>
  <div class="intro-x dropdown mr-auto sm:mr-6">
    <div
      class="dropdown-toggle notification notification--bullet cursor-pointer"
      @click.prevent="toggleDropdown"
    >
      <i class="ri-notification-2-line dark:text-gray-300" />
    </div>
    <div
      v-if="showDropdown"
      v-clickaway="toggleDropdown"
      class="notification-content dropdown-box"
      :class="{ show: showDropdown }"
    >
      <div
        class="notification-content__box dropdown-box__content box bg-white dark:bg-dark-6"
      >
        <div class="notification-content__title">
          Notifications
        </div>
        <div
          v-for="notification in notifications"
          :key="notification.id"
          class="cursor-pointer relative flex items-center mb-5"
        >
          <div class="w-12 h-12 flex-none image-fit mr-1">
            <img
              alt="Quant Lines"
              class="rounded-full"
              src="/images/profile-4.jpg"
            >
            <div
              class="w-3 h-3 bg-theme-9 absolute right-0 bottom-0 rounded-full border-2 border-white"
            />
          </div>
          <div class="ml-2 overflow-hidden">
            <div class="flex items-center">
              <a
                class="font-medium truncate mr-5"
                href="javascript:"
              >John Travolta</a>
              <div class="text-xs text-gray-500 ml-auto whitespace-no-wrap">
                05:09 AM
              </div>
            </div>
            <div class="w-full truncate text-gray-600">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry&#039;s standard dummy
              text ever since the 1500
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, computed } from "vue";
import { useStore } from "vuex";
import clickaway from "@/directives/clickaway";

export default {
  name: "Notifications",
  directives: { clickaway },
  setup() {
    const store = useStore();
    const state = reactive({
      showDropdown: false,
      notifications: []
    });
    store.dispatch("profile/getNotifications");

    function toggleDropdown() {
      state.showDropdown = !state.showDropdown;
    }

    return {
      toggleDropdown,
      showDropdown: computed(() => state.showDropdown),
      notifications: computed(() => state.notifications)
    };
  }
};
</script>
