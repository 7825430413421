<template>
  <div class="absolute w-full top-5 z-75 md:hidden">
    <div class="px-4">
      <div class="flex">
        <a
          class="flex mr-auto"
          @click.prevent="goToHome"
        >
          <img
            alt="Quant Lines"
            class="w-6"
            src="/images/logo.svg"
          >
        </a>
        <a
          id="mobile-menu-toggler"
          href="javascript:"
          @click.prevent="toggleMenu"
        >
          <i class="ri-menu-3-fill ri-lg transform -rotate-90" />
        </a>
      </div>
      <ul
        v-if="showMenu"
        class="shadow-md rounded-md border-theme-24 bg-theme-2 mt-1 px-2 py-2 space-y-2 w-full h-full"
      >
        <li>
          <a
            href="#"
            class="flex space-x-1"
            :class="{ 'border-b': $route.path === '/dashboard' }"
            @click.prevent="goToHome"
          >
            <div class="flex text-theme-1"><i class="ri-lg ri-home-4-line text-indigo-800" /></div>
            <div class="menu__title">Dashboard</div>
          </a>
        </li>
        <template
          v-for="(item, key) in menuItems"
          :key="key"
        >
          <li>
            <router-link
              :to="{ name: item.route }"
              class="flex space-x-1"
            >
              <div class="menu__icon flex">
                <i
                  :class="item.icon"
                  class="text-indigo-800"
                />
              </div>
              <div class="menu__title">
                {{ item.name }}
              </div>
            </router-link>
          </li>
          <ul
            v-if="item.children.length"
            class=""
          >
            <li>
              <a
                href="side-menu-light-crud-data-list.html"
                class="flex"
              >
                <div class="side-menu__icon"><i data-feather="activity" /></div>
                <div class="side-menu__title">Data List</div>
              </a>
            </li>
            <li>
              <a
                href="side-menu-light-crud-form.html"
                class="menu"
              >
                <div class="side-menu__icon"><i data-feather="activity" /></div>
                <div class="side-menu__title">Form</div>
              </a>
            </li>
          </ul>
          <li
            v-if="item.hasDevider"
            class="side-nav__devider my-6"
          />
        </template>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "MobileMenu",
  data() {
    return {
      showMenu: false,
      menuItems: [
        {
          name: "Projects",
          route: "dashboard.client.project.index",
          icon: "ri-lg ri-folder-line",
          hasDevider: false,
          children: []
        },
        {
          name: "Teams",
          route: "dashboard.team.index",
          icon: "ri-lg ri-team-line",
          hasDevider: false,
          children: []
        }
      ]
    };
  },
  computed: {
    ...mapGetters("profile", ["currentUser"])
  },
  watch: {
    $route: function() {
      this.showMenu = false;
    }
  },
  methods: {
    goToHome() {
      this.$router.push({ name: "DashboardIndex" });
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    }
  }
};
</script>
