<template>
  <div
    :class="containerClass"
    role="alert"
    aria-live="assertive"
    aria-atomic="true"
  >
    <div class="p-toast-message-content">
      <span :class="iconClass" />
      <div class="p-toast-message-text">
        <span class="font-medium">{{ message.summary }}</span>
        <div class="p-toast-detail">
          {{ message.detail }}
        </div>
      </div>
      <button
        v-if="message.closable !== false"
        v-ripple
        class="p-toast-icon-close p-link"
        type="button"
        @click="onCloseClick"
      >
        <span class="p-toast-icon-close-icon pi pi-times" />
      </button>
    </div>
  </div>
</template>

<script>
import Ripple from '../ripple/Ripple';

export default {
    directives: {
        'ripple': Ripple
    },
    props: {
        message: null
    },
    emits: ['close'],
    closeTimeout: null,
    computed: {
        containerClass() {
            return ['border-l-4 p-3 mb-2', {
                'p-toast-message-info': this.message.severity === 'info',
                'bg-yellow-300 border-yellow-600': this.message.severity === 'warning',
                'p-toast-message-error': this.message.severity === 'error',
                'p-toast-message-success': this.message.severity === 'success'
            }];
        },
        iconClass() {
            return ['p-toast-message-icon pi', {
                'pi-info-circle': this.message.severity === 'info',
                'pi-exclamation-triangle': this.message.severity === 'warn',
                'pi-times': this.message.severity === 'error',
                'pi-check': this.message.severity === 'success'
            }];
        }
    },
    mounted() {
        if (this.message.life) {
            this.closeTimeout = setTimeout(() => {
                this.close();
            }, this.message.life)
        }
    },
    methods: {
        close() {
            this.$emit('close', this.message);
        },
        onCloseClick() {
            if (this.closeTimeout) {
                clearTimeout(this.closeTimeout);
            }

            this.close();
        }
    }
}
</script>