<template>
  <div class="intro-x relative mr-3 sm:mr-6">
    <div class="search hidden sm:block">
      <input
        v-model.lazy="filterQuery"
        type="text"
        class="search__input input placeholder-theme-13"
        placeholder="Jump to team or project..."
        @click="toggleResults"
      >
      <i class="ri-search-line search__icon dark:text-gray-300" />
    </div>
    <a
      class="notification sm:hidden"
      href=""
    >
      <i class="ri-search-2-line notification__icon dark:text-gray-300" />
    </a>
    <div
      v-if="resultsShow"
      v-clickaway="toggleResults"
      class="search-result z-50"
      :class="{ show: resultsShow }"
    >
      <div class="search-result__content bg-white z-50">
        <div class="search-result__content__title">
          Results
        </div>
        <div class="z-50">
          <template
            v-for="result in results"
            :key="result.id"
          >
            <router-link
              :to="getRoute(result)"
              class="flex items-center mb-2"
            >
              <div
                v-if="result.is_team"
                class="w-8 h-8 bg-theme-18 text-theme-9 flex items-center justify-center rounded-full"
              >
                <i class="ri-team-fill ri-lg" />
              </div>
              <div
                v-else-if="result.is_project"
                class="w-8 h-8 bg-blue-200 text-blue-500 flex items-center justify-center rounded-full"
              >
                <i class="ri-file-2-fill ri-lg" />
              </div>
              <div class="ml-3">
                {{ result.name }}
              </div>
            </router-link>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, reactive, watchEffect } from "vue";
import { useStore } from "vuex";
import clickaway from "@/directives/clickaway";

export default {
  name: "Search",
  directives: { clickaway },
  setup() {
    const store = useStore();
    const state = reactive({
      filterQuery: "",
      resultsShow: false,
      results: []
    });

    watchEffect(() => {
      if (state.filterQuery) {
        store.dispatch("search/search", state.filterQuery).then(r => {
          state.results = r.data;
        });
      }
    });

    function toggleResults() {
      state.resultsShow = !state.resultsShow;
    }

    function getRoute(result) {
      if (result.is_team) {
        return {
          name: "dashboard.team.show",
          params: { id: result.id }
        };
      }else if(result.is_project) {
        return {
          name: "dashboard.client.project.show",
          params: { id: result.id }
        };
      }
    }

    return {
      filterQuery: computed({
        get: () => state.filterQuery,
        set: value => (state.filterQuery = value)
      }),
      toggleResults,
      resultsShow: computed(() => state.resultsShow),
      results: computed(() => state.results),
      getRoute
    };
  }
};
</script>

<style scoped></style>
