<template>
  <div class="bg-theme-1">
    <div class="py-0 md:py-5 md:px-2 md:px-8">
      <mobile-menu />
      <Toast position="bottom-right" />
      <div class="flex">
        <!-- BEGIN: Side Menu -->
        <nav-bar class="absolute md:relative" />
        <!-- END: Side Menu -->

        <!-- BEGIN: Content -->
        <div class="content top-0 md:top-auto">
          <!-- BEGIN: Top Bar -->
          <top-bar class="mt-12 md:mt-auto" />
          <!-- END: Top Bar -->
          <router-view />
        </div>
        <!-- END: Content -->
      </div>
    </div>
  </div>
</template>

<script>
import MobileMenu from "./partials/dashboard/MobileMenu.vue";
import NavBar from "./partials/dashboard/Navbar.vue";
import TopBar from "./partials/dashboard/TopBar.vue";
import Toast from "@/components/toast/Toast";

export default {
  name: "Dashboard",
  components: {
    MobileMenu,
    NavBar,
    TopBar,
    Toast
  },
  async created() {
    await this.$store.dispatch("profile/getAuthUser");
  }
};
</script>

<style scoped></style>
