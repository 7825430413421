<script>
import { reactive, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import clickaway from "@/directives/clickaway";

export default {
  name: "User",
  directives: { clickaway },
  setup() {
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      showDropdown: false
    });

    function toggleDropdown() {
      state.showDropdown = !state.showDropdown;
    }

    function logout() {
      store.dispatch("auth/logout").then(() => {
        router.push({ name: "Login" });
      });
    }

    return {
      toggleDropdown,
      logout,
      showDropdown: computed(() => state.showDropdown),
      notifications: computed(() => state.notifications),
      currentUser: computed(() => store.getters["profile/currentUser"])
    };
  }
};
</script>

<template>
  <div class="intro-x dropdown w-8 h-8">
    <div
      class="dropdown-toggle w-8 h-8 rounded-full overflow-hidden shadow-lg image-fit zoom-in"
      @click.prevent="toggleDropdown"
    >
      <img
        alt="Quant Lines"
        :src="currentUser.profile_photo"
      >
    </div>
    <div
      v-if="showDropdown"
      v-clickaway="toggleDropdown"
      class="dropdown-box w-56"
      :class="{ show: showDropdown }"
    >
      <div
        class="dropdown-box__content box bg-theme-3 dark:bg-dark-6 text-white"
      >
        <div class="p-4 border-b border-theme-1 dark:border-dark-3">
          <div class="font-medium">
            {{ currentUser.first_name + " " + currentUser.last_name }}
          </div>
        </div>
        <div class="p-2">
          <router-link
            :to="{ name: 'dashboard.profile.index' }"
            class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
          >
            <i class="mr-2 ri-user-fill" /> Profile
          </router-link>
          <router-link
            :to="{ name: 'dashboard.profile.settings' }"
            class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
          >
            <i class="mr-2 ri-settings-2-fill" /> Settings
          </router-link>
          <a
            href=""
            class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
          >
            <i class="mr-2 ri-question-fill" /> Help
          </a>
        </div>
        <div class="p-2 border-t border-theme-1 dark:border-dark-3">
          <a
            href="#"
            class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
            @click.prevent="logout"
          >
            <i class="mr-2 ri-logout-circle-r-fill" /> Logout
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
